import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AutoProffScriptSpot } from '@ncg/data';

@Component({
    selector: 'ncg-autoproff-script-spot',
    template: `
        <section class="container columns" [class.is-centered]="data.isCentered">
            <div class="ap-embed"></div>
            <ncg-auto-proff></ncg-auto-proff>
        </section>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoproffScriptSpotComponent {
    static ref = 'autoproffscript';

    @Input()
    public data: AutoProffScriptSpot;
}
