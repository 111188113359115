import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { IModelPageResponse } from '@ncg/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FeatureDetectionService } from '../../core/feature-detection.service';
import { HeaderService } from '../../core/header.service';
import { ScrollStatusService } from '../../core/scroll-status.service';
import { SidePanelService } from '../../side-panel/side-panel.service';
import { SpotsConfig } from '../../spots/spots-config';

@Component({
    selector: 'ncg-model-page-header',
    template: `
        <div class="model-header__wrapper" [style.height]="modelHeaderHeight">
            <div
                #modelHeaderTablet
                class="model-header is-hidden-mobile"
                [ngClass]="{ 'model-header--is-sticky': isFixed }"
                [style.transform]="moveMenuDown ? 'translate3d(0, ' + headerHeight + 'px, 0)' : ''"
            >
                <ncg-model-page-header-inner [modelPage]="modelPage" *ngIf="modelPage.pageNavigation">
                    <div class="model-header__nav">
                        <ncg-model-page-nav
                            [modelPage]="modelPage"
                            (openTestDriveClicked)="openTestDrive()"
                            (openOfferClicked)="openOffer()"
                        ></ncg-model-page-nav>
                    </div>
                </ncg-model-page-header-inner>
            </div>
            <div
                #modelHeaderMobile
                class="model-header is-hidden-tablet"
                [ngClass]="{ 'model-header--is-sticky': isFixed }"
                [style.transform]="moveMenuDown ? 'translate3d(0, ' + headerHeight + 'px, 0)' : ''"
                [style.top]="isFixed ? -headerHeightMobile + 'px' : 0"
            >
                <ncg-model-page-header-inner [modelPage]="modelPage" *ngIf="modelPage.pageNavigation">
                    <div class="model-header__nav" #modelPageNavMobile>
                        <ncg-model-page-nav
                            [modelPage]="modelPage"
                            (openTestDriveClicked)="openTestDrive()"
                            (openOfferClicked)="openOffer()"
                        ></ncg-model-page-nav>
                    </div>
                </ncg-model-page-header-inner>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelPageHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() modelPage: IModelPageResponse;
    @Input() schema?: any;
    @ViewChild('modelHeaderMobile') modelHeaderMobile: ElementRef;
    @ViewChild('modelHeaderTablet') modelHeaderTablet: ElementRef;
    @ViewChild('modelPageNavMobile') modelPageNavMobile: ElementRef;
    modelPageNavMobileHeight = 0;
    currentDevice: string;
    modelHeaderHeight: number | string = 'auto;';
    modelHeaderHeightValue = 0;
    isFixed: boolean;
    moveMenuDown: boolean;
    headerHeight: number;
    headerHeightMobile: number;
    prevScrollPosition: number;
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly headerService: HeaderService,
        private readonly scrollStatusService: ScrollStatusService,
        @Inject(SpotsConfig) public readonly spotsConfig: SpotsConfig,
        private readonly featureDetectionService: FeatureDetectionService,
        private readonly sidePanelService: SidePanelService
    ) {}

    ngOnInit() {
        if (this.featureDetectionService.isBrowser()) {
            const root = document.documentElement;
            root.style.setProperty('--model-page-header-is-animating', `${this.spotsConfig.isModelPageHeaderAnimating}`);
        }

        if (this.spotsConfig.isModelPageHeaderAnimating) {
            this.scrollStatusService
                .getScrollPosition()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((position: number) => {
                    this.handleModelHeaderPosition(position);
                });

            this.headerService
                .getHeaderEvents()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((height: number) => {
                    this.headerHeight = height;
                    this.prevScrollPosition = height;
                    this.cd.markForCheck();
                });
        }
    }

    ngAfterViewInit() {
        if (this.spotsConfig.isModelPageHeaderAnimating) {
            if (this.modelHeaderMobile?.nativeElement.offsetHeight) {
                this.modelHeaderHeight = `${this.modelHeaderMobile.nativeElement.offsetHeight}px`;
                this.modelHeaderHeightValue = this.modelHeaderMobile.nativeElement.offsetHeight;
                this.modelPageNavMobileHeight = this.modelPageNavMobile?.nativeElement?.offsetHeight;
                this.currentDevice = 'mobile';
            } else if (this.modelHeaderTablet.nativeElement.offsetHeight) {
                this.modelHeaderHeight = `${this.modelHeaderTablet.nativeElement.offsetHeight}px`;
                this.currentDevice = 'tablet';
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    handleModelHeaderPosition(position: number) {
        // Mobile
        if (this.currentDevice === 'mobile') {
            this.headerHeightMobile = this.modelHeaderHeightValue - (this.modelPageNavMobileHeight - 1); // Minus 1 is to fix border height
            if (position > this.modelHeaderHeightValue) {
                if (position > this.prevScrollPosition) {
                    this.moveMenuDown = false;
                    this.isFixed = true;
                } else {
                    this.isFixed = true;
                    this.moveMenuDown = true;
                }
                this.prevScrollPosition = position;
            } else if (position <= this.headerHeightMobile) {
                this.isFixed = false;
                this.moveMenuDown = false;
                this.prevScrollPosition = this.headerHeightMobile;
            }

            // Tablet+
        } else if (this.currentDevice === 'tablet') {
            if (position > this.headerHeight) {
                if (position > this.prevScrollPosition) {
                    this.moveMenuDown = false;
                    this.isFixed = true;
                    this.prevScrollPosition = position - 1;
                } else {
                    this.isFixed = true;
                    this.moveMenuDown = true;
                    this.prevScrollPosition = position;
                }
            } else if (position <= this.headerHeight) {
                this.isFixed = false;
                this.moveMenuDown = false;
                this.prevScrollPosition = this.headerHeight;
            }
        }
        this.cd.markForCheck();
    }

    openTestDrive(): void {
        if (!this.modelPage.lmsId) {
            return;
        }
        this.sidePanelService.openTestDrive({
            isModelPage: true,
            collectMarketingConsent: this.modelPage.testDriveCollectMarketingConsent,
            locationWhitelist: this.modelPage.locationWhitelist,
            locationBlacklist: this.modelPage.locationBlacklist,
            models: [
                {
                    name: this.modelPage.modelParentName || '',
                    url: this.modelPage.modelParentUrl || '',
                    pimId: this.modelPage.pimModelId || '',
                    image: this.modelPage.image,
                    lms: this.modelPage.lmsId,
                },
            ],
        });
    }

    openOffer(): void {
        if (!this.modelPage.lmsId) {
            return;
        }
        this.sidePanelService.openOffer({
            id: this.modelPage.lmsId,
            isCashAllowed: this.modelPage.isCashAllowed,
            isLoanAllowed: this.modelPage.isLoanAllowed,
            isLeasingAllowed: this.modelPage.isLeasingAllowed,
            isUsedCar: false,
            name: this.modelPage.modelParentName || '',
            subtitle: '',
            image: this.modelPage.image,
        });
    }
}
