import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import {
    BaseAppController,
    BloomreachService,
    FeatureDetectionService,
    GlobalStateService,
    MetaService,
    NavigationService,
    ScrollService,
    SettingsService,
    TrackingService,
} from '@ncg/ui';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ncg-root',
    templateUrl: '../../../../libs/ui/src/lib/base-app/base-app.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends BaseAppController {
    constructor(
        cd: ChangeDetectorRef,
        transferState: TransferState,
        translateService: TranslateService,
        settingsService: SettingsService,
        navigationService: NavigationService,
        featureDetectionService: FeatureDetectionService,
        metaService: MetaService,
        scrollService: ScrollService,
        bloomreachService: BloomreachService,
        globalStateService: GlobalStateService,
        trackingService: TrackingService
    ) {
        super(
            cd,
            transferState,
            translateService,
            settingsService,
            navigationService,
            featureDetectionService,
            metaService,
            scrollService,
            bloomreachService,
            globalStateService,
            trackingService
        );
    }
}
