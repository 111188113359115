import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AutoProffService } from '../../../auto-proff/auto-proff.service';

// To simplify async loading, we have this helper component that initializes autoProff.
// By having a component do it, we simply render this alongside any related codesnippet. That way the template controls when to load.
@Component({
    selector: 'ncg-auto-proff',
    standalone: true,
    template: '',
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoProffComponent implements OnInit {
    constructor(private readonly autoProffService: AutoProffService) {}

    public ngOnInit(): void {
        this.autoProffService.init({ embedSelector: '.ap-embed' }).catch((e) => {
            console.warn(`Tried to intialize AutoProff in AutoProffComponent. Failed with error: "${e}"`);
        });
    }
}
