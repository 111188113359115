import { ChangeDetectorRef, Directive, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { IActionBar, ISchemaOrganization, NcgAppComponentInterface } from '@ncg/data';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject, takeWhile } from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import { LazyContentAliases } from '../$lazy-content/models/lazy-content.models';
import { BloomreachService } from '../core/bloomreach.service';
import { FeatureDetectionService } from '../core/feature-detection.service';
import { GlobalStateService } from '../core/global-state.service';
import { MetaService } from '../core/meta.service';
import { ScrollService } from '../core/scroll.service';
import { SettingsService } from '../core/settings.service';
import { TrackingService } from '../core/tracking.service';
import { NavigationService } from '../navigation/navigation.service';

@Directive() // Using directive decorator to fix DI errors.
export abstract class BaseAppController implements OnInit, OnDestroy, NcgAppComponentInterface {
    private readonly unsubscribe = new Subject<void>();

    protected previewOverlayAlias = LazyContentAliases.PreviewOverlay;
    protected lazyContentAliases = LazyContentAliases;
    protected actionBarData?: IActionBar;
    protected schema?: ISchemaOrganization;
    protected isDefaultActiveFlow?: boolean;
    protected isFrontPage?: boolean;

    @ViewChild('vcFooter', { read: ViewContainerRef, static: false })
    protected viewContainerFooter: ViewContainerRef;

    @ViewChild('vcModelPageNav', { read: ViewContainerRef, static: true })
    public viewContainerModelPageNav: ViewContainerRef;

    protected constructor(
        protected readonly cd: ChangeDetectorRef,
        protected readonly transferState: TransferState,
        protected readonly translateService: TranslateService,
        protected readonly settingsService: SettingsService,
        protected readonly navigationService: NavigationService,
        protected readonly featureDetectionService: FeatureDetectionService,
        protected readonly metaService: MetaService,
        protected readonly scrollService: ScrollService,
        protected readonly bloomreachService: BloomreachService,
        protected readonly globalStateService: GlobalStateService,
        protected readonly trackingService: TrackingService,
        protected readonly useCompactFrontPage?: boolean
    ) {
        this.bloomreachService.initBloomreach();
        this.scrollService.initScrollRestoration();
    }

    public ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public ngOnInit() {
        this.initTranslations();
        this.initIsFrontPage();

        this.settingsService
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.initJsonLd(settings.companyName);
                this.initActionBar(settings.actionBar);
                this.cd.markForCheck();
            });

        this.initLazyFooter();
        this.init3rdPartyScripts();
    }

    private initTranslations() {
        this.translateService.use('platform');
    }

    private initIsFrontPage() {
        const isFrontPageKey = makeStateKey<boolean>('isFrontPage');
        if (this.transferState.hasKey(isFrontPageKey)) {
            this.isFrontPage = this.transferState.get(isFrontPageKey, this.isFrontPage);
        }

        this.navigationService
            .isFrontPage()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                if (this.featureDetectionService.isServer()) {
                    this.transferState.set(isFrontPageKey, data);
                }
                this.isFrontPage = data;
                this.cd.markForCheck();
            });
    }

    private initJsonLd(companyName: string) {
        const baseUrl = this.metaService.getBaseUrl();
        this.schema = {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            'url': baseUrl,
            'logo': `${baseUrl}/assets/icons/logo.svg`,
            'name': companyName,
        };
    }

    private initActionBar(actionBarData?: IActionBar) {
        this.actionBarData = actionBarData;
    }

    private initLazyFooter() {
        this.globalStateService
            .getStates()
            .pipe(
                map(({ activeFlow }) => activeFlow),
                distinctUntilChanged(),
                takeUntil(this.unsubscribe)
            )
            .subscribe((activeFlow) => {
                this.isDefaultActiveFlow = activeFlow === 'default';

                if (activeFlow === 'configurator') {
                    return;
                }

                // In order to make sure footer is shown even when starting on pages that do not have it,
                // we must subscribe to changes in the state, and inject accordingly
                const loadFooter = async () => {
                    this.viewContainerFooter?.clear();
                    // Ignore path inconsistency. Path is relative to parent class, not base class.
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const { FooterComponent } = await import('@features/layout/footer/footer.component');
                    this.viewContainerFooter?.createComponent(FooterComponent);
                    this.cd.markForCheck();
                };
                if (this.featureDetectionService.isBrowser()) {
                    window.setTimeout(() => {
                        loadFooter().catch(console.error);
                    }, 0);
                }
            });
    }

    private init3rdPartyScripts() {
        if (this.featureDetectionService.isBrowser()) {
            // Microsoft Clarity:
            // Start tracking once consent is given and script is loaded.
            // Stop tracking if consent is rescinded.
            combineLatest([
                this.trackingService.isClarityLoaded$.pipe(takeWhile((isLoaded) => !isLoaded, true)),
                this.trackingService.statisticConsentGiven(),
            ])
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(([isLoaded, hasConsent]) => {
                    if (isLoaded) {
                        if (hasConsent) {
                            this.trackingService.initClarityTracking();
                        } else {
                            this.trackingService.initClarityTracking(true);
                        }
                    }
                });

            // Microsoft Clarity:
            // Load script.
            this.settingsService
                .get()
                .pipe(take(1), takeUntil(this.unsubscribe))
                .subscribe((settings) => {
                    if (settings.clarityId) {
                        this.trackingService.loadClarityScript(settings.clarityId);
                    }
                });

            // Hotjar tracking - initialize only if statistics consent is given.
            // Deprecated - remove once transition to Clarity is complete.
            combineLatest([this.settingsService.get().pipe(take(1)), this.trackingService.statisticConsentGiven()])
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(([settings, consent]) => {
                    const { hjId } = settings;

                    if (consent && hjId) {
                        this.trackingService.initHotjar(parseInt(hjId));
                    }
                });
        }
    }
}
