import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactFormSpot, IInquiryDto, NcgLocationFormCategory, NgcApiLocation } from '@ncg/data';
import { firstValueFrom, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { AppHttpErrorResponse } from '../core/app-http-error.response';
import { randomId } from '../core/helpers';
import { ScrollService } from '../core/scroll.service';
import { SettingsService } from '../core/settings.service';
import { TrackingService } from '../core/tracking.service';
import { FormService } from './form.service';
import { LOCATION_QUERY_CATEGORY } from './location-select.component';

@Component({
    selector: 'ncg-contact-form',
    template: `
        <div #mainElement class="contact-form" *ngIf="form">
            <form [formGroup]="form" (submit)="onSubmit()" *ngIf="!states.submitted; else success">
                <ncg-location-select
                    [allowedCategory]="data.availableCategory"
                    [parentForm]="form"
                    [category]="category"
                    [isDepartmentsAllowed]="true"
                    [locationWhitelist]="data.locationWhitelist"
                    [locationBlacklist]="data.locationBlacklist"
                    (isLocationsFound)="onLocationState($event)"
                    (categoryChange)="onCategoryChange($event)"
                    [isTouched]="form?.get('location')?.touched"
                    [allowSpareParts]="true"
                ></ncg-location-select>
                <div class="field">
                    <div class="control">
                        <label [for]="firstNameId" class="label">{{ 'forms.firstname' | translate }}*</label>
                        <input
                            [id]="firstNameId"
                            formControlName="firstname"
                            name="firstname"
                            class="input"
                            [class.is-danger]="errors('firstname')"
                            type="text"
                            required
                        />
                        <p class="help is-danger" *ngIf="errors('firstname')">{{ 'forms.error_required_field' | translate }}</p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="lastNameId" class="label">{{ 'forms.lastname' | translate }}*</label>
                        <input
                            [id]="lastNameId"
                            formControlName="lastname"
                            name="lastname"
                            class="input"
                            [class.is-danger]="errors('lastname')"
                            type="text"
                            required
                        />
                        <p class="help is-danger" *ngIf="errors('lastname')">{{ 'forms.error_required_field' | translate }}</p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="emailId" class="label">{{ 'forms.email' | translate }}*</label>
                        <input
                            [id]="emailId"
                            formControlName="email"
                            name="email"
                            class="input"
                            [class.is-danger]="errors('email')"
                            type="email"
                            required
                        />
                        <p class="help is-danger" *ngIf="errors('email')">{{ 'forms.error_email' | translate }}</p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="phoneId" class="label">{{ 'forms.mobile' | translate }}*</label>
                        <div class="phone">
                            <span class="phoneCode">
                                <input
                                    style="width: 100px; margin-right: 2px"
                                    type="country-code"
                                    autocomplete="tel-country-code"
                                    class="input"
                                    name="selectedCountryCode"
                                    formControlName="selectedCountryCode"
                                    [class.is-danger]="errors('selectedCountryCode')"
                                />
                            </span>
                            <span class="phoneOnly">
                                <input
                                    [id]="phoneId"
                                    formControlName="phone_mobile"
                                    name="phone"
                                    class="input"
                                    type="tel"
                                    autocomplete="tel-national"
                                    [class.is-danger]="errors('phone_mobile')"
                                />
                            </span>
                        </div>
                        <p class="help is-danger" *ngIf="errors('selectedCountryCode')">{{ 'forms.error_phone_country' | translate }}</p>
                        <p class="help is-danger" *ngIf="errors('phone_mobile')">{{ 'forms.error_phone' | translate }}</p>
                    </div>
                </div>
                <div *ngIf="!data.hideMessage" class="field">
                    <div class="control">
                        <label [for]="messageId" class="label">{{ 'forms.message' | translate }}*</label>
                        <textarea
                            [id]="messageId"
                            formControlName="message"
                            class="textarea"
                            [class.is-danger]="errors('message')"
                            required
                        ></textarea>
                    </div>
                    <p class="help is-danger" *ngIf="errors('message')">{{ 'forms.error_required_field' | translate }}</p>
                </div>
                <div class="field" *ngIf="data.collectMarketingConsent">
                    <div class="control"><ncg-consent [parentForm]="form"></ncg-consent></div>
                </div>
                <div class="field is-grouped is-grouped-centered">
                    <div class="control">
                        <button
                            class="button is-primary is-fullwidth"
                            [class.is-loading]="states.processing"
                            [disabled]="states.processing || form?.disabled"
                        >
                            {{ 'forms.submit_inquiry' | translate }}
                        </button>
                    </div>
                </div>
                <ncg-legal *ngIf="privacyPolicy" [legalText]="privacyPolicy"></ncg-legal>
                <div class="notification is-primary" *ngIf="errorState">
                    <ng-container *ngIf="errorState === 'api'; else genericError"> {{ 'forms.error_field' | translate }} </ng-container>
                    <ng-template #genericError>{{ 'forms.error_submit' | translate }}</ng-template>
                </div>
            </form>
            <ng-template #success><ncg-rich-text [html]="data.successMessage"></ncg-rich-text></ng-template>
        </div>
    `,
})
export class ContactFormComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    states = {
        submitted: false,
        processing: false,
    };
    errorState: '' | 'api' | 'server' = '';
    isLocationsDisabled = true;
    category: NcgLocationFormCategory;
    privacyPolicy?: string;

    firstNameId: string;
    lastNameId: string;
    emailId: string;
    phoneId: string;
    messageId: string;

    form: FormGroup;

    @Input() data: ContactFormSpot = {} as ContactFormSpot;
    @ViewChild('mainElement') private mainElement?: ElementRef<HTMLDivElement>;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly formService: FormService,
        private readonly cd: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly settingsService: SettingsService,
        private readonly trackingService: TrackingService,
        private readonly scrollService: ScrollService
    ) {}

    ngOnInit() {
        // Form has to be initialized inside onInit since it needs this.data to be defined.
        this.form = this.fb.group(
            {
                firstname: ['', Validators.required],
                lastname: ['', Validators.required],
                email: ['', [Validators.email, Validators.required, FormService.emailValidator()]],
                phone_mobile: ['', [Validators.required, FormService.countryPhoneValidator(''), FormService.numbersOnlyValidator()]],
                selectedCountryCode: ['', [Validators.required, FormService.countryCodeValidator()]],
                message: this.data?.hideMessage ? '' : ['', [Validators.required, Validators.minLength(2)]],
                location: ['', [Validators.required]],
                consent: '',
            },
            { updateOn: 'blur' }
        );

        this.firstNameId = randomId('firstname');
        this.lastNameId = randomId('lastname');
        this.emailId = randomId('email');
        this.phoneId = randomId('phone');
        this.messageId = randomId('message');

        this.settingsService
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.privacyPolicy = settings.privacyPolicy;
                this.cd.markForCheck();
                // Patch the form value inside the settings subscribe block
                this.form?.patchValue({
                    selectedCountryCode: FormService.convertCountryCodeToPhoneCode(settings.seoCountry),
                });
            });

        this.setInitialCategory();

        this.cd.markForCheck();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setInitialCategory() {
        if (this.data.availableCategory) {
            this.category = this.data.availableCategory;
        } else {
            const category = (this.route.snapshot.queryParamMap.get(LOCATION_QUERY_CATEGORY) as NcgLocationFormCategory) ?? 'retail';

            switch (category) {
                case 'workshop':
                    this.category = 'workshop';
                    break;
                case 'spareparts':
                    this.category = 'spareparts';
                    break;

                default:
                    this.category = 'retail';
                    break;
            }
        }
    }

    onLocationState(hasLocations: boolean) {
        this.isLocationsDisabled = hasLocations;

        if (!this.isLocationsDisabled && this.form) {
            this.form.disable();
            this.errorState = 'server';
        } else {
            this.form?.enable();
            this.errorState = '';
        }
    }
    onCategoryChange(event: NcgLocationFormCategory) {
        this.category = event;
    }

    private createContactModel(value: any): IInquiryDto {
        const origin = (window.location.host.indexOf('www.') && window.location.host) || window.location.host.replace('www.', '');
        const message = this.data.hardcodedMessage ? [this.data.hardcodedMessage, value.message].filter((x) => x).join('\n\n') : value.message;

        return {
            storeCode: value.location.store_code,
            firstname: value.firstname,
            lastname: value.lastname,
            email: value.email.toLowerCase(),
            phone_mobile: `${value.selectedCountryCode}${value.phone_mobile}`,
            message,
            consent: value.consent || undefined,
            origin,
            list: this.data.listId,
            meta: {
                campaign: {
                    utm: this.trackingService.getUtmValue(),
                    title: this.data.campaign,
                },
            },
        };
    }
    onSubmit(): void {
        if (this.states.processing) {
            return;
        }

        this.errorState = '';
        this.cd.markForCheck();
        if (!this.form) {
            return;
        }

        if (this.form.invalid) {
            FormService.markControlsAsTouched(this.form);
            this.cd.detectChanges();
            return;
        }

        this.states.processing = true;

        const value = this.form.value;
        const location: NgcApiLocation = value.location;

        if (!location) {
            this.states.processing = false;
            this.cd.markForCheck();
            return;
        }

        const model = this.createContactModel(value);

        firstValueFrom(this.formService.submitInquiry(model, this.category))
            .then(() => {
                this.onSuccess();
            })
            .catch((err: AppHttpErrorResponse) => {
                this.states.processing = false;

                this.errorState = 'server';
                if (err.validationErrors.length) {
                    this.errorState = 'api';
                    if (this.form) {
                        FormService.markValidationErrors(err.validationErrors, this.form);
                    }
                }

                this.cd.markForCheck();
            })
            .then(() => {
                this.scrollService.scrollToElement(this.mainElement?.nativeElement, { block: 'center' });
            });
    }

    private onSuccess() {
        const url = this.data.successRedirect?.url;
        if (url) {
            this.router.navigateByUrl(url);
            return;
        }

        this.states.processing = false;
        this.states.submitted = true;
        this.cd.markForCheck();
    }

    errors = (controlName: string) => FormService.errors(controlName, this.form);
}
