import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ExtendedHeroSpot, IImage } from '@ncg/data';
import { FeatureDetectionService } from '../../core/feature-detection.service';
import { CinemaAspect, MobileAspectRatio } from '../../utils/helpers/aspect-ratio';
import { breakpointQueries, breakpoints } from '../../utils/helpers/breakpoints';
import { ImageUrl } from '../../utils/helpers/image-helper';
import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-extended-hero-spot',
    template: `
        <div class="extended-hero-spot">
            <div class="extended-hero-spot__container" id="extended-hero-spot-container">
                <ncg-link *ngIf="data.links && data.links.length === 1" [link]="data.links[0]" styleClass="extended-hero-spot__link"></ncg-link>
                <ncg-video
                    class="extended-hero-spot__media-container"
                    [ngClass]="{ 'extended-hero-spot__media-container--media-filter': data.mediaFilter }"
                    *ngIf="data.video && data.video?.url; else imageTmpl"
                    [video]="data.video"
                    [poster]="data.image"
                    [preload]="isFirstSpot ? 'auto' : 'metadata'"
                ></ncg-video>
                <ng-template #imageTmpl>
                    <picture
                        *ngIf="data.image as image"
                        class="extended-hero-spot__media-container"
                        [ngClass]="{ 'extended-hero-spot__media-container--media-filter': data.mediaFilter }"
                    >
                        <source [srcset]="mobileSrcset" [media]="breakpointQueries.mobile" />
                        <source [srcset]="mobileSrcset" [media]="breakpointQueries.mobileLarge" />
                        <source [srcset]="mobileSrcset" [media]="breakpointQueries.tablet" />
                        <source [srcset]="desktopSrcset" [media]="breakpointQueries.desktop" />

                        <ng-container *ngIf="isFirstSpot; else defaultImageTmpl">
                            <img
                                [src]="image | imageUrl: { width: 1440, mode: 'crop' }"
                                [alt]="image.altText || data.title || ''"
                                sizes="97vw"
                                fetchpriority="high"
                            />
                        </ng-container>
                        <ng-template #defaultImageTmpl>
                            <img
                                [src]="image | imageUrl: { width: 1440, mode: 'crop' }"
                                [alt]="image.altText || data.title || ''"
                                sizes="97vw"
                                loading="lazy"
                                ncgImageLoad
                            />
                        </ng-template>
                    </picture>
                </ng-template>
                <div class="extended-hero-spot__content" ncgLoadIn *ngIf="data.title || data.subtitle || data.price || data.links">
                    <ng-container *ngIf="data.useAsHeader; else noHeader">
                        <h1 *ngIf="data.title" class="extended-hero-spot__title" [innerHTML]="data.title | usedCarsTags | async | safe: 'html'"></h1>
                        <h2
                            *ngIf="data.subtitle"
                            class="extended-hero-spot__subtitle"
                            ncgInterceptLinks
                            [innerHTML]="data.subtitle | usedCarsTags | async | safe: 'html'"
                        ></h2>
                    </ng-container>
                    <ng-template #noHeader>
                        <h2 *ngIf="data.title" class="extended-hero-spot__title" [innerHTML]="data.title | usedCarsTags | async | safe: 'html'"></h2>
                        <div
                            *ngIf="data.subtitle"
                            class="extended-hero-spot__subtitle"
                            ncgInterceptLinks
                            [innerHTML]="data.subtitle | usedCarsTags | async | safe: 'html'"
                        ></div>
                    </ng-template>
                    <p *ngIf="data.price" class="extended-hero-spot__price">
                        {{ 'models.pricefrom' | translate: { price: data.price } }}
                    </p>
                    <div class="extended-hero-spot__cta" *ngIf="data.links">
                        <ncg-button
                            *ngFor="let link of data.links; let first = first"
                            [link]="link"
                            [buttonClass]="first ? 'is-extended-hero' : 'is-extended-hero-secondary'"
                        ></ncg-button>
                    </div>
                </div>
                <ng-container
                    *ngIf="
                        data.legal && (data.legal.energyLabels || data.legal.legalText || data.additionalLegal?.length || data.legal.extraHeroText)
                    "
                >
                    <div class="extended-hero-spot__overlay"></div>
                    <div
                        [ngClass]="{
                            'legal-text-moved': data.showLegalTextUnderMobile,
                            'legal-text-moved-desktop': data.showLegalTextUnderDesktop
                        }"
                        class="extended-hero-spot__legal hero-slider-spot__legal"
                    >
                        <ncg-legal
                            [energyLabels]="data.legal.energyLabels"
                            [legalText]="'<b>' + data.legal.modelName + ':</b> ' + ' ' + data.legal.legalText"
                        ></ncg-legal>
                        <ng-container *ngFor="let item of data.additionalLegal" class="extra-legal">
                            <ncg-legal
                                [energyLabels]="item.energyLabels"
                                [legalText]="'<b>' + item.modelName + ':</b> ' + ' ' + item.legalText"
                            ></ncg-legal>
                        </ng-container>
                        <ncg-legal [legalText]="data.legal.extraHeroText"></ncg-legal>
                    </div>
                </ng-container>
            </div>
            <div class="legal-moved"></div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtendedHeroSpotComponent extends SpotBaseDirective implements OnInit, OnDestroy, AfterViewInit {
    static ref = 'extendedhero';
    @Input() data: ExtendedHeroSpot;

    public desktopAspectRatio: number = CinemaAspect;
    public mobileAspectRatio: number = MobileAspectRatio;
    public mobileSrcset: string;
    public desktopSrcset: string;
    public srcset: string;
    public breakpointQueries = breakpointQueries;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly renderer: Renderer2,
        private readonly el: ElementRef,
        private readonly featureDetection: FeatureDetectionService
    ) {
        super();
    }

    public ngOnDestroy(): void {
        if (this.featureDetection.isBrowser()) {
            window.removeEventListener('resize', this.moveLegalTextIfRequired);
        }
    }

    public ngOnInit() {
        if (this.data.image) {
            this.generateSrcset(this.data.image);
        }
    }

    public ngAfterViewInit() {
        if (this.featureDetection.isBrowser()) {
            this.moveLegalTextIfRequired(); // Initial check after view loads
            window.addEventListener('resize', this.moveLegalTextIfRequired); // Check on resize
        }
    }

    private readonly moveLegalTextIfRequired = () => {
        const isMobile = window.innerWidth <= breakpoints.tablet;
        const legalTextMoved = this.data.showLegalTextUnderMobile === true;
        const legalTextMovedDesktop = this.data.showLegalTextUnderDesktop === true;

        if (legalTextMoved && isMobile) {
            let newLegalDiv = this.el.nativeElement.querySelector('.legal-moved');
            if (!newLegalDiv) {
                newLegalDiv = this.renderer.createElement('div');
                this.renderer.addClass(newLegalDiv, 'legal-moved');
                this.renderer.appendChild(this.el.nativeElement.querySelector('.extended-hero-spot-container'), newLegalDiv);
            }

            const legalTextMovedElement = this.el.nativeElement.querySelector('.extended-hero-spot__legal.legal-text-moved');
            if (legalTextMovedElement) {
                this.renderer.appendChild(newLegalDiv, legalTextMovedElement);
            }
        } else {
            const originalContainer = this.el.nativeElement.querySelector('.extended-hero-spot-container');
            const legalTextMovedElement = this.el.nativeElement.querySelector('.extended-hero-spot__legal.legal-text-moved');
            if (legalTextMovedElement && originalContainer) {
                this.renderer.appendChild(originalContainer, legalTextMovedElement);
            }
        }
        // desktop

        if (legalTextMovedDesktop && !isMobile) {
            let newLegalDiv = this.el.nativeElement.querySelector('.legal-moved');
            if (!newLegalDiv) {
                newLegalDiv = this.renderer.createElement('div');
                this.renderer.addClass(newLegalDiv, 'legal-moved');
                this.renderer.appendChild(this.el.nativeElement.querySelector('.extended-hero-spot-container'), newLegalDiv);
            }

            const legalTextMovedElement = this.el.nativeElement.querySelector('.extended-hero-spot__legal.legal-text-moved-desktop');
            if (legalTextMovedElement) {
                this.renderer.appendChild(newLegalDiv, legalTextMovedElement);
            }
        } else {
            const originalContainer = this.el.nativeElement.querySelector('.extended-hero-spot-container');
            const legalTextMovedElement = this.el.nativeElement.querySelector('.extended-hero-spot__legal.legal-text-moved-desktop');
            if (legalTextMovedElement && originalContainer) {
                this.renderer.appendChild(originalContainer, legalTextMovedElement);
            }
        }
    };

    private generateSrcset(image: IImage | undefined): void {
        if (!image || !image.url) {
            return;
        }

        const mobileSrcsetArray = new Set();
        const desktopSrcsetArray = new Set();

        for (const width of Object.values(breakpoints)) {
            const imageOptions: Parameters<typeof ImageUrl>[1] = {
                mode: 'crop',
            };

            // if original image width is known, only try to add images that are smaller than the image width to avoid upscaling.
            const maxWidth = image.width || 1440;
            const strWidth = width <= maxWidth ? width : maxWidth;
            const strRetinaWidth = width * 2 <= maxWidth ? width * 2 : maxWidth;

            if (width < breakpoints.tablet) {
                imageOptions.heightratio = this.mobileAspectRatio;
                const str = `${ImageUrl(image, { ...imageOptions, width: strWidth })} ${width}w`;
                const retinaStr = `${ImageUrl(image, { ...imageOptions, width: strRetinaWidth })} ${width * 2}w`;
                mobileSrcsetArray.add(str);
                mobileSrcsetArray.add(retinaStr);
            } else {
                imageOptions.heightratio = this.desktopAspectRatio;
                const str = `${ImageUrl(image, { ...imageOptions, width: strWidth })} ${width}w`;
                const retinaStr = `${ImageUrl(image, { ...imageOptions, width: strRetinaWidth })} ${width * 2}w`;
                desktopSrcsetArray.add(str);
                desktopSrcsetArray.add(retinaStr);
            }
        }

        this.mobileSrcset = Array.from(mobileSrcsetArray).join(', ');
        this.desktopSrcset = Array.from(desktopSrcsetArray).join(', ');
        this.cd.markForCheck();
    }
}
