import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BookServiceFormSpot, IBookServiceDto, NcgLocationFormCategory, NgcApiLocation } from '@ncg/data';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AppHttpErrorResponse } from '../core/app-http-error.response';
import { leftFillNum, randomId } from '../core/helpers';
import { ScrollService } from '../core/scroll.service';
import { SettingsService } from '../core/settings.service';
import { TrackingService } from '../core/tracking.service';
import { ImageObjectType } from '../image-upload/image-upload.component';
import { I18n } from '../utils/helpers/datepicker-i18n';
import { FormService } from './form.service';

@Component({
    selector: 'ncg-book-service-form',
    template: `
        <div #mainElement class="book-service-form" *ngIf="form">
            <form [formGroup]="form" (submit)="onSubmit()" *ngIf="!states.submitted; else success">
                <!-- TODO remove applyFilter when locations are updated with service category NIC-2494 -->
                <ncg-location-select
                    [parentForm]="form"
                    controlName="distributor"
                    [category]="category"
                    [allowedCategory]="category"
                    [locationWhitelist]="data.locationWhitelist"
                    [locationBlacklist]="data.locationBlacklist"
                    (isLocationsFound)="onLocationState($event)"
                    [isTouched]="form?.get('distributor')?.touched"
                    [applyFilter]="true"
                ></ncg-location-select>
                <div class="field">
                    <label [for]="dateId" class="label">{{ 'forms.datepicker_label' | translate }}*</label>
                    <div class="control has-icons-right">
                        <input
                            [id]="dateId"
                            class="input"
                            autocomplete="off"
                            placeholder="dd-mm-yyyy"
                            name="dp"
                            formControlName="datetime"
                            [class.is-danger]="errors('datetime')"
                            [minDate]="minDate"
                            [maxDate]="maxDate"
                            ngbDatepicker
                            [autoClose]="true"
                            [markDisabled]="disabledDays"
                            #dp="ngbDatepicker"
                            (click)="dp.open()"
                            (focus)="!dontOpenDatepicker && dp.open(); dontOpenDatepicker = dp.isOpen()"
                            ncgDatepickerI18n
                        />
                        <span class="icon is-right is-clickable" (click)="dp.toggle()">
                            <svg-icon-sprite
                                src="calendar"
                                [viewBox]="'0 0 30 30'"
                                [width]="'30px'"
                                [height]="'30px'"
                                aria-hidden="true"
                                classes=""
                            ></svg-icon-sprite>
                        </span>
                        <p class="help is-danger" *ngIf="errors('datetime')">{{ 'forms.datepicker_invalid_date' | translate }}</p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="firstNameId" class="label">{{ 'forms.firstname' | translate }}*</label>
                        <input
                            [id]="firstNameId"
                            formControlName="firstname"
                            name="firstname"
                            class="input"
                            [class.is-danger]="errors('firstname')"
                            type="text"
                            required
                        />
                        <p class="help is-danger" *ngIf="errors('firstname')">{{ 'forms.error_required_field' | translate }}</p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="lastNameId" class="label">{{ 'forms.lastname' | translate }}*</label>
                        <input
                            [id]="lastNameId"
                            formControlName="lastname"
                            name="lastname"
                            class="input"
                            [class.is-danger]="errors('lastname')"
                            type="text"
                            required
                        />
                        <p class="help is-danger" *ngIf="errors('lastname')">{{ 'forms.error_required_field' | translate }}</p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="emailId" class="label">{{ 'forms.email' | translate }}*</label>
                        <input
                            [id]="emailId"
                            formControlName="email"
                            name="email"
                            class="input"
                            [class.is-danger]="errors('email')"
                            type="email"
                            required
                        />
                        <p class="help is-danger" *ngIf="errors('email')">{{ 'forms.error_email' | translate }}</p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="phoneId" class="label">{{ 'forms.mobile' | translate }}*</label>
                        <div class="phone">
                            <span class="phoneCode">
                                <input
                                    style="width: 100px; margin-right: 2px"
                                    type="country-code"
                                    autocomplete="tel-country-code"
                                    class="input"
                                    name="selectedCountryCode"
                                    formControlName="selectedCountryCode"
                                    [class.is-danger]="errors('selectedCountryCode')"
                                />
                            </span>
                            <span class="phoneOnly">
                                <input
                                    [id]="phoneId"
                                    formControlName="phone_mobile"
                                    name="phone"
                                    class="input"
                                    type="tel"
                                    autocomplete="tel-national"
                                    [class.is-danger]="errors('phone_mobile')"
                                />
                            </span>
                        </div>
                        <p class="help is-danger" *ngIf="errors('selectedCountryCode')">{{ 'forms.error_phone_country' | translate }}</p>
                        <p class="help is-danger" *ngIf="errors('phone_mobile')">{{ 'forms.error_phone' | translate }}</p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="licensePlateId" class="label">{{ 'forms.license_plate' | translate }}*</label>
                        <input
                            [id]="licensePlateId"
                            role="presentation"
                            formControlName="licensePlate"
                            name="licensePlate"
                            class="input"
                            type="text"
                            [class.is-danger]="errors('licensePlate')"
                            required
                        />
                        <p class="help is-danger" *ngIf="errors('licensePlate')">{{ 'forms.error_required_field' | translate }}</p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="mileageId" class="label">{{ 'forms.mileage' | translate }}*</label>
                        <input
                            [id]="mileageId"
                            formControlName="mileage"
                            class="input"
                            pattern="[0-9]*"
                            [class.is-danger]="errors('mileage')"
                            required
                        />
                        <p class="help is-danger" *ngIf="errors('mileage')">{{ 'forms.error_required_field' | translate }}</p>
                    </div>
                </div>
                <!-- image upload -->
                <div class="field">
                    <div class="control">
                        <ng-container *ngIf="data.enableImageUpload">
                            <label [for]="messageId" class="label">{{ 'forms.image_upload_label' | translate }}</label>
                            <ncg-image-upload (fileChange)="onFileChange($event)"></ncg-image-upload>
                        </ng-container>
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <ng-container *ngIf="data.messageRequired; else messageOptional" class="container">
                            <label [for]="messageId" class="label">{{ 'forms.message' | translate }}*</label>
                            <textarea
                                placeholder="{{ 'forms.message_damage_placeholder' | translate }}"
                                [id]="messageId"
                                required
                                formControlName="message"
                                class="textarea"
                                [class.is-danger]="errors('message')"
                            ></textarea>
                            <p class="help is-danger" *ngIf="errors('message')">{{ 'forms.error_required_field' | translate }}</p>
                        </ng-container>
                        <ng-template #messageOptional>
                            <label [for]="messageId" class="label">{{ 'forms.message' | translate }}</label>
                            <textarea
                                [id]="messageId"
                                formControlName="message"
                                class="textarea"
                                placeholder="{{ 'forms.message_damage_placeholder' | translate }}"
                            ></textarea>
                        </ng-template>
                    </div>
                </div>
                <div class="field" *ngIf="data.collectMarketingConsent">
                    <div class="control"><ncg-consent [parentForm]="form"></ncg-consent></div>
                </div>
                <div class="field is-grouped is-grouped-centered">
                    <div class="control">
                        <button
                            class="button is-primary is-fullwidth"
                            [class.is-loading]="states.processing"
                            [disabled]="states.processing || form?.disabled"
                        >
                            {{ 'forms.submit_service' | translate }}
                        </button>
                    </div>
                </div>
                <ncg-legal *ngIf="privacyPolicy" [legalText]="privacyPolicy"></ncg-legal>
                <div class="notification is-primary" *ngIf="errorState">
                    <ng-container *ngIf="errorState === 'api'; else genericError"> {{ 'forms.error_field' | translate }} </ng-container>
                    <ng-template #genericError>{{ 'forms.error_submit' | translate }}</ng-template>
                </div>
            </form>
            <ng-template #success> <ncg-rich-text [html]="data.successMessage"></ncg-rich-text> </ng-template>
        </div>
    `,
})
export class BookServiceFormComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    @Input() data: BookServiceFormSpot = {} as BookServiceFormSpot;

    @ViewChild('mainElement') private mainElement?: ElementRef<HTMLDivElement>;

    category: NcgLocationFormCategory = 'workshop';

    states = {
        submitted: false,
        processing: false,
    };

    errorState: '' | 'api' | 'server' = '';
    minDate: NgbDateStruct;
    maxDate: NgbDateStruct;
    dontOpenDatepicker = false;
    days = 1;

    isLocationsDisabled = true;
    disabledDays: (date: NgbDate, current: { month: number } | undefined) => boolean;
    privacyPolicy = '';

    firstNameId: string;
    lastNameId: string;
    emailId: string;
    phoneId: string;
    messageId: string;
    dateId: string;
    licensePlateId: string;
    mileageId: string;
    images: { key: string; value: string }[] = [];

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly formService: FormService,
        private readonly cd: ChangeDetectorRef,
        private readonly ngbCalendar: NgbCalendar,
        private readonly router: Router,
        private readonly settingsService: SettingsService,
        private readonly trackingService: TrackingService,
        private readonly scrollService: ScrollService,

        private readonly _i18n: I18n // Not ideal, but to combat async issues with i18n and datePicker, we inject the service here, to make sure the correct language is set
    ) {}

    form = this.fb.group(
        {
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            email: ['', [Validators.email, Validators.required, FormService.emailValidator()]],
            phone_mobile: ['', [Validators.required, FormService.countryPhoneValidator(''), FormService.numbersOnlyValidator()]],
            selectedCountryCode: ['', [Validators.required, FormService.countryCodeValidator()]],
            distributor: ['', [Validators.required]],
            message: this.data.messageRequired ? ['', Validators.required] : [''],
            datetime: ['', [Validators.required]],
            licensePlate: ['', [Validators.required]],
            mileage: ['', [Validators.required, Validators.pattern(/[0-9]*/)]],
            imageUpload: [this.images || [], ''],
            consent: '',
        },
        { updateOn: 'blur' }
    );
    errors = (controlName: string) => FormService.errors(controlName, this.form);
    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnInit() {
        this.firstNameId = randomId('firstname');
        this.lastNameId = randomId('lastname');
        this.emailId = randomId('email');
        this.phoneId = randomId('phone');
        this.messageId = randomId('message');
        this.dateId = randomId('date');
        this.licensePlateId = randomId('license_plate');
        this.mileageId = randomId('mileage');

        this.settingsService
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.privacyPolicy = settings.privacyPolicy;

                // Patch the form value inside the settings subscribe block
                this.form?.patchValue({
                    selectedCountryCode: FormService.convertCountryCodeToPhoneCode(settings.seoCountry),
                });
                this.cd.markForCheck();
            });

        this.setDatePickerSettings();
    }

    private getWeekday(date: Date, weekdaysToSkip: number): Date {
        const dayOfWeek = date.getDay();
        const isWeekend = dayOfWeek === 6 || dayOfWeek === 0; // 6 = Saturday, 0 = Sunday
        if (!isWeekend && weekdaysToSkip === 0) {
            return date;
        }
        const nextDay: Date = new Date(date.setDate(date.getDate() + 1));
        const updatedWeekDaysToSkip = isWeekend ? weekdaysToSkip : weekdaysToSkip - 1;
        return this.getWeekday(nextDay, updatedWeekDaysToSkip);
    }

    onFileChange($event: ImageObjectType[]) {
        this.images = $event.map((image: ImageObjectType) => ({
            key: image.name,
            value: image.base64File.split(',')[1],
        }));
    }

    setDatePickerSettings() {
        const currentDate = new Date();
        const disabledFromDate = this.getWeekday(currentDate, this.data.numberOfDaysDisabled);
        this.disabledDays = (date: NgbDate) => this.ngbCalendar.getWeekday(date) >= 6;

        this.minDate = {
            day: disabledFromDate.getDate(),
            month: disabledFromDate.getMonth() + 1,
            year: disabledFromDate.getFullYear(),
        };

        this.maxDate = {
            day: currentDate.getDate(),
            month: currentDate.getMonth() + 1,
            year: currentDate.getFullYear() + 2,
        };
    }

    onLocationState(event: any) {
        this.isLocationsDisabled = event;

        if (!this.isLocationsDisabled && this.form) {
            this.form.disable();
            this.errorState = 'server';
        }
    }
    onSubmit(): void {
        if (this.states.processing) {
            return;
        }

        this.errorState = '';
        this.cd.markForCheck();

        if (!this.form) {
            return;
        }

        if (this.form.invalid || this.form.disabled) {
            FormService.markControlsAsTouched(this.form);
            return;
        }

        this.states.processing = true;
        const value = this.form.value;
        const location: NgcApiLocation = value.distributor;
        const datetime = `${value.datetime.year}-${leftFillNum(value.datetime.month, 2)}-${leftFillNum(value.datetime.day, 2)}`;
        const customNcgOriginName = (window.location.host.indexOf('www.') && window.location.host) || window.location.host.replace('www.', '');

        if (!location || location.category?.code !== 'workshop') {
            this.states.processing = false;
            this.cd.markForCheck();
            return;
        }
        const model: IBookServiceDto = {
            origin: customNcgOriginName,
            storeCode: location.store_code ?? '',
            firstname: value.firstname,
            lastname: value.lastname,
            email: value.email.toLowerCase(),
            phone_mobile: `${value.selectedCountryCode}${value.phone_mobile}`,
            message: value.message,
            datetime,
            licensePlate: value.licensePlate,
            mileage: value.mileage,
            consent: value.consent || undefined,
            base64Attachments: this.images,
            meta: {
                campaign: {
                    utm: this.trackingService.getUtmValue(),
                    title: this.data.campaign,
                },
            },
        };

        firstValueFrom(this.formService.submitBookService(model))
            .then(() => {
                this.onSuccess();
            })
            .catch((err: AppHttpErrorResponse) => {
                this.states.processing = false;

                this.errorState = 'server';
                if (err.validationErrors.length) {
                    this.errorState = 'api';
                    if (this.form) {
                        FormService.markValidationErrors(err.validationErrors, this.form);
                    }
                }

                this.cd.markForCheck();
            })
            .then(() => {
                this.scrollService.scrollToElement(this.mainElement?.nativeElement, { block: 'center' });
            });
    }

    private onSuccess() {
        const url = this.data.successRedirect?.url;
        if (url) {
            this.router.navigateByUrl(url);
            return;
        }

        this.states.processing = false;
        this.states.submitted = true;
        this.cd.markForCheck();
    }
}
