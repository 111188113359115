import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { type IsActiveMatchOptions } from '@angular/router';
import { IMegaMenuSettings, INavigationResponse } from '@ncg/data';
import { LazyContentAliases } from '../../$lazy-content/models/lazy-content.models';
import { SettingsService } from '../../core/settings.service';

@Component({
    selector: 'ncg-mega-menu-alternate',
    template: `
        <div class="mega-menu__container mega-menu__container--animate">
            <div class="container">
                <div class="columns is-gapless pb-5">
                    <div class="mega-menu__alt-links" [style.columns]="columnCount">
                        <div class="mega-menu__item mega-menu__item--animate" *ngFor="let child of children; trackBy: trackById">
                            <ng-container *ngIf="child.pageLink?.isExternal; else internalLink">
                                <a
                                    class="mega-menu__link mega-menu__link--no-image"
                                    [href]="child.pageLink?.url"
                                    routerLinkActive="is-active"
                                    [routerLinkActiveOptions]="activeMatchOptions"
                                    [attr.target]="child.pageLink?.target"
                                >
                                    {{ child.name }}
                                </a>
                            </ng-container>
                            <ng-template #internalLink>
                                <a
                                    class="mega-menu__link mega-menu__link--no-image"
                                    [routerLink]="child.pageLink?.url || child.url"
                                    routerLinkActive="is-active"
                                    [routerLinkActiveOptions]="activeMatchOptions"
                                >
                                    {{ child.name }}
                                </a>
                            </ng-template>
                        </div>
                    </div>
                    <ng-template
                        *ngIf="altMenuSettings.iconLinks?.length"
                        ncgLazyComponentLoader
                        [data]="altMenuSettings"
                        [alias]="altMenuIconLinksKey"
                    ></ng-template>
                    <ng-template
                        *ngIf="altMenuSettings.highlightImage?.url"
                        ncgLazyComponentLoader
                        [data]="altMenuSettings"
                        [alias]="altMenuHighlightKey"
                    ></ng-template>
                </div>
                <ng-container *ngIf="item?.template === 'modelOverviewPage' && (settingsService.getMenuSpot() | async) as menuSpotData">
                    <ng-template *ngIf="menuSpotData.enableMenuSpot" ncgLazyComponentLoader [data]="menuSpotData" [alias]="menuSpotKey"></ng-template>
                </ng-container>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MegaMenuAlternateComponent implements OnInit {
    public children: INavigationResponse[] = [];
    public altMenuSettings: IMegaMenuSettings = {};
    public columnCount: number = 1;
    public altMenuHighlightKey = LazyContentAliases.AltMenuHighlight;
    public altMenuIconLinksKey = LazyContentAliases.AltMenuIconLinks;
    public menuSpotKey = LazyContentAliases.MenuSpot;

    @Input()
    public item?: INavigationResponse;

    readonly activeMatchOptions: IsActiveMatchOptions = {
        queryParams: 'ignored',
        matrixParams: 'exact',
        paths: 'exact',
        fragment: 'exact',
    };

    constructor(
        private readonly cd: ChangeDetectorRef,
        public readonly settingsService: SettingsService
    ) {}

    public ngOnInit(): void {
        if (this.item?.children?.length && this.item.megaMenu) {
            this.children = this.item.children.filter((child) => child.includeInNavigation && !child.hideInMenu);
            this.altMenuSettings = this.item.megaMenu;
            this.setColumnCount(this.children.length);
        }
    }

    public trackById(index: number, item: INavigationResponse) {
        return item.id ?? index;
    }

    private setColumnCount(itemCount: number = this.columnCount) {
        const itemsPerColumn = 6;
        this.columnCount = Math.ceil(itemCount / itemsPerColumn);
        this.cd.markForCheck();
    }
}
