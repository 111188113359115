<div class="site__wrapper">
    <ngx-json-ld [json]="schema" *ngIf="schema"></ngx-json-ld>
    <div class="site__sticky-top" id="header-portal"></div>
    <div class="content__wrapper is-compact" [class.is-compact]="useCompactFrontPage && isFrontPage">
        <ng-template #vcModelPageNav></ng-template>
        <ncg-header *ngIf="isDefaultActiveFlow" [class.is-hidden]="useCompactFrontPage && isFrontPage"></ncg-header>
        <router-outlet></router-outlet>
    </div>
    <ng-container *ngIf="isDefaultActiveFlow" #vcFooter></ng-container>
    <div class="site__sticky-bottom" id="footer-portal"></div>
    <ng-container *ngIf="actionBarData">
        <ng-template ncgLazyComponentLoader [data]="actionBarData" [alias]="lazyContentAliases.ActionBar"></ng-template>
    </ng-container>
    <ncg-page-transition></ncg-page-transition>
    <ng-template ncgLazyComponentLoader [alias]="previewOverlayAlias"></ng-template>
</div>
<ncg-up></ncg-up>
