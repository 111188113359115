import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModelAccessoriesSpot } from '@ncg/data';
import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-model-accessories-spot',
    template: `
        <div *ngIf="data" class="accessories-spot container" ncgLoadIn>
            <div class="accessories-spot__content" *ngIf="data.title || data.subtitle">
                <h2 class="accessories-spot__content-title" *ngIf="data.title">
                    {{ data.title }}
                </h2>
                <p class="accessories-spot__content-subtitle" *ngIf="data.subtitle">{{ data.subtitle }}</p>
            </div>
            <div class="accessories-spot__groups" *ngIf="data.accessories">
                <ncg-accordion
                    class="accessories-spot__group"
                    *ngFor="let group of data.accessories; let i = index; trackBy: trackByIndex"
                    [expanded]="data.accordionsOpen === 'all' || (data.accordionsOpen === 'first' && i === 0)"
                >
                    <h2 class="accessories-spot__title accessories-spot__title--group" title>{{ group.category }}</h2>
                    <div content class="accessories-spot__items">
                        <ncg-card
                            *ngFor="let item of group.items"
                            variant="view"
                            class="accessories-spot__item"
                            contentClass="accessories-spot__item-content"
                        >
                            <img
                                class="mb-5"
                                *ngIf="item.imageUrl"
                                [src]="item.imageUrl"
                                [alt]="item.title"
                                [srcset]="item.imageUrl | resourceSrcset"
                                sizes="300px"
                                loading="lazy"
                                card-image
                                ncgImageLoad
                            />
                            <h3 class="accessories-spot__title accessories-spot__title--item" [innerHTML]="item.title | safe: 'html'"></h3>
                            <p class="accessories-spot__item-text" *ngIf="item.description">
                                {{ item.description }}
                            </p>
                            <p class="accessories-spot__item-tiny-text" *ngIf="item.infoText">
                                {{ item.infoText }}
                            </p>
                            <p
                                class="accessories-spot__item-tiny-text accessories-spot__item-tiny-text--light accessories-spot__margin-top-auto"
                                *ngIf="data.showItemNumbers && item.itemNumberText"
                            >
                                {{ 'model_accessories_spot.item_number' | translate }}
                                {{ item.itemNumberText }}
                            </p>
                            <div
                                class="accessories-spot__item-price"
                                [class.accessories-spot__margin-top-auto]="!(data.showItemNumbers && item.itemNumberText)"
                                *ngIf="item.displayPrice"
                            >
                                {{ item.displayPrice }}
                            </div>
                        </ncg-card>
                    </div>
                </ncg-accordion>
            </div>
        </div>
    `,
    styleUrls: ['model-accessories-spot.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelAccessoriesSpotComponent extends SpotBaseDirective {
    static ref = 'modelaccessories';

    @Input()
    public data?: ModelAccessoriesSpot;

    public trackByIndex(index: number) {
        return index;
    }
}
