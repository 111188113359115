import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ILink } from '@ncg/data';

@Component({
    selector: 'ncg-top-menu',
    standalone: true,
    template: `
        <nav class="top-menu">
            <ng-container *ngFor="let link of links; trackBy: trackByIndex">
                <ng-container *ngIf="link?.isExternal; else internalLink">
                    <a class="top-menu__link" [href]="link.url" [attr.target]="link.target" rel="noopener">
                        {{ link.name }}
                    </a>
                </ng-container>
                <ng-template #internalLink>
                    <a class="top-menu__link" [routerLink]="link.url" [attr.target]="link.target">
                        {{ link.name }}
                    </a>
                </ng-template>
            </ng-container>
        </nav>
    `,
    styleUrls: ['./top-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

    imports: [NgForOf, NgIf, RouterLink],
})
export class TopMenuComponent {
    @Input()
    public links?: ILink[];

    public trackByIndex(index: number) {
        return index;
    }
}
