import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { IFacetList, IFilterPageLayout } from '@ncg/data';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProductsFacade } from '../../+state/products/products.facade';

@Component({
    selector: 'ncg-filter-facets',
    template: `
        <div class="columns is-multiline filter-facets">
            <div class="column is-full border-bottom is-hidden-touch">
                <div class="filter-facets__item" [ngClass]="{ 'filter-facets__item--padding': filterPageLayout === 'filterInSidepanel' }">
                    <ncg-product-search></ncg-product-search>
                </div>
            </div>
            <ng-container *ngFor="let facetGroup of facetList; trackBy: trackByFn; let last = last">
                <div class="column is-full" [class.border-bottom]="!last" *ngIf="facetGroup?.facets?.length || facetGroup.facetGroups?.length">
                    <div class="filter-facets__item" [ngClass]="{ 'filter-facets__item--padding': filterPageLayout === 'filterInSidepanel' }">
                        <ncg-expansion-panel [expanded]="!facetGroup.isCollapsed">
                            <h3 class="filter-facets__title" expansion-title>
                                <ng-container [ngSwitch]="facetGroup.facetKey">
                                    <ng-container *ngSwitchCase="'make.keyword'">{{ 'facet_types.make_keyword' | translate }} </ng-container>
                                    <ng-container *ngSwitchCase="'bodytypes.keyword'">
                                        {{ 'facet_types.bodytypes_keyword' | translate }}</ng-container
                                    >
                                    <ng-container *ngSwitchCase="'dealername.keyword'"> {{ 'facet_types.dealername' | translate }}</ng-container>
                                    <ng-container *ngSwitchCase="'effect'">{{ 'facet_types.effect' | translate }}</ng-container>
                                    <ng-container *ngSwitchCase="'geartype.keyword'"> {{ 'facet_types.geartype_keyword' | translate }}</ng-container>
                                    <!--isvan is disabled as it is already in the "bodytypes.keyword" facet.-->
                                    <!--<ng-container *ngSwitchCase="'isvan'">{{ 'facet_types.isvan' | translate }}</ng-container>-->
                                    <ng-container *ngSwitchCase="'mileage'">{{ 'facet_types.mileage' | translate }} </ng-container>
                                    <ng-container *ngSwitchCase="'model.keyword'">{{ 'facet_types.model_keyword' | translate }} </ng-container>
                                    <ng-container *ngSwitchCase="'price'">{{ 'facet_types.price' | translate }}</ng-container>
                                    <ng-container *ngSwitchCase="'propellant.keyword'">{{
                                        'facet_types.propellant_keyword' | translate
                                    }}</ng-container>
                                    <ng-container *ngSwitchCase="'series.keyword'"> {{ 'facet_types.series_keyword' | translate }}</ng-container>
                                    <ng-container *ngSwitchCase="'year'">{{ 'facet_types.year' | translate }}</ng-container>
                                    <ng-container *ngSwitchCase="'only'">{{ 'facet_types.only' | translate }}</ng-container>
                                    <ng-container *ngSwitchCase="'payment'">{{ 'facet_types.payment' | translate }}</ng-container>
                                </ng-container>
                                {{ facetGroup.selectedCount ? '(' + facetGroup.selectedCount + ')' : '' }}
                            </h3>
                            <img expansion-icon alt="icon" src="assets/images/down.svg" width="30" height="30" />
                            <ng-container [ngSwitch]="facetGroup.type">
                                <ng-container *ngSwitchCase="'toggles'">
                                    <ncg-filter-facet-toggle [facetGroup]="facetGroup"></ncg-filter-facet-toggle>
                                </ng-container>
                                <ng-container *ngSwitchCase="'range'">
                                    <ncg-filter-facet-range [facetGroup]="facetGroup"></ncg-filter-facet-range>
                                </ng-container>
                                <ng-container *ngSwitchCase="'buttons'">
                                    <ncg-filter-facet [facetData]="facetGroup"></ncg-filter-facet>
                                </ng-container>
                            </ng-container>
                        </ncg-expansion-panel>
                    </div>
                </div>
            </ng-container>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFacetsComponent implements OnInit, OnDestroy {
    facetList?: IFacetList[] = [];
    filterPageLayout?: IFilterPageLayout;
    showPremiumFacet = false;
    showUsedFacet = false;
    premiumText = '';
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly productsFacade: ProductsFacade,
        private readonly cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        combineLatest([this.productsFacade.layout$, this.productsFacade.facetList$, this.productsFacade.selectedPremiumText$])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([layout, list, premiumText]) => {
                this.showPremiumFacet = layout?.showPremiumFacet || false;
                this.showUsedFacet = layout?.showUsedFacet || false;
                this.premiumText = premiumText || '';

                this.transformFilterFacetsList(list);

                this.cd.markForCheck();
            });
    }

    transformFilterFacetsList(list?: IFacetList[]) {
        let isAnyMakeSelected = false;
        let isBmwSelected = false;
        let makeIsVisible = true;
        const newList: IFacetList[] = [];

        if (list) {
            for (const item of list) {
                if (item.facetKey === 'make.keyword') {
                    const makeFacets = item.facets || [];
                    for (const facet of makeFacets) {
                        if (facet.selected) {
                            isAnyMakeSelected = true;
                        }

                        if (facet.key?.toLowerCase().trim() === 'bmw' && facet.selected) {
                            isBmwSelected = true;
                        }
                    }

                    // Remove 'make' if there is less than 2 choices
                    if (makeFacets.length < 2 && !isAnyMakeSelected) {
                        makeIsVisible = false;
                        continue;
                    }
                }

                // Remove model and series if there isn't chosen a make. Only show series if BMW is selected
                if (
                    makeIsVisible &&
                    ((!isAnyMakeSelected && item.facetKey === 'model.keyword') || (!isBmwSelected && item.facetKey === 'series.keyword'))
                ) {
                    continue;
                }

                // expand `model` if any make is selected
                // or `series` if BMW is selected
                if (isAnyMakeSelected) {
                    if ((isAnyMakeSelected && item.facetKey === 'model.keyword') || (isBmwSelected && item.facetKey === 'series.keyword')) {
                        newList.push({ ...item, isCollapsed: false });
                        continue;
                    }
                }

                if (item.facetKey === 'only') {
                    newList.push({
                        ...item,
                        facetGroups: item.facetGroups?.filter((group) => {
                            if (group.facetKey === 'isdemo') {
                                return this.showUsedFacet;
                            } else if (group.facetKey === 'ispremium') {
                                return this.showPremiumFacet && Boolean(this.premiumText);
                            }

                            return true;
                        }),
                    });
                    continue;
                }

                newList.push(item);
            }
        }

        this.facetList = newList.map((facetList) => {
            if (facetList.facetGroups) {
                let selected = 0;
                facetList.facetGroups.forEach((facetGroup) => {
                    facetGroup.facets?.filter((x) => x.selected).forEach(() => selected++);
                });
                return {
                    ...facetList,
                    selectedCount: selected > 0 ? selected : undefined,
                };
            } else {
                const selected = facetList.facets?.filter((facet) => facet.selected).length || 0;
                return {
                    ...facetList,
                    selectedCount: selected > 0 ? selected : undefined,
                };
            }
        });

        this.cd.markForCheck();
    }

    trackByFn(index: number, item: any) {
        return item.facetKey || index;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
