import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { BydHeroSpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';
import { SpotsConfig } from '../spots-config';

@Component({
    selector: 'ncg-byd-hero-spot',
    template: `
        <div [ngClass]="{ 'container is-full': !allowNarrowContent }" *ngIf="data" ncgLoadIn>
            <div
                [ngClass]="{
                    'container is-fullwidth': !spotsConfig.isHeroFullViewport && !allowNarrowContent,
                    'is-full': spotsConfig.isHeroFullViewport && !allowNarrowContent
                }"
            >
                <div class="byd-hero-spot">
                    <ncg-video
                        *ngIf="data.video && data.video?.url; else imageTmpl"
                        class="byd-hero-spot__media-container"
                        [ngClass]="{ 'byd-hero-spot__media-container--media-filter': data.mediaFilter }"
                        [aspectRatioClass]="'is-ratio-byd-hero'"
                        [video]="data.video"
                        [poster]="data.image"
                    ></ncg-video>
                    <ng-template #imageTmpl>
                        <picture
                            *ngIf="data.image"
                            class="byd-hero-spot__media-container"
                            [ngClass]="{ 'byd-hero-spot__media-container--media-filter': data.mediaFilter }"
                        >
                            <ng-container *ngIf="isFirstSpot; else defaultImageTmpl">
                                <img
                                    [src]="data.image | srcset: { retina: false, imageOptions: { width: 1440 } }"
                                    [srcset]="data.image | srcset: { widths: [375, 425, 768, 1024] }"
                                    [alt]="data.image.altText || data.title || ''"
                                    sizes="100vw"
                                    fetchpriority="high"
                                />
                            </ng-container>
                            <ng-template #defaultImageTmpl>
                                <img
                                    [src]="data.image | srcset: { retina: false, imageOptions: { width: 1440 } }"
                                    [srcset]="data.image | srcset: { widths: [375, 425, 768, 1024] }"
                                    [alt]="data.image.altText || data.title || ''"
                                    sizes="100vw"
                                    loading="lazy"
                                    ncgImageLoad
                                />
                            </ng-template>
                        </picture>
                    </ng-template>
                    <div class="byd-hero-spot__wrapper" ncgLoadIn *ngIf="data.title || data.body || data.topLinks">
                        <ncg-spot-headline *ngIf="data.title" [level]="this.level" [headlineClass]="this.headlineClass">
                            {{ data.title }}
                        </ncg-spot-headline>

                        <p class="byd-hero-spot__content" *ngIf="data.price || data.body">
                            <ng-container *ngIf="data.price; else bodyTmlp">
                                {{ 'models.pricefrom' | translate: { price: data.price } }}
                            </ng-container>
                            <ng-template #bodyTmlp>
                                {{ data.body }}
                            </ng-template>
                        </p>
                        <div class="byd-hero-spot__top-links" *ngIf="data.topLinks">
                            <ncg-button *ngFor="let topLink of data.topLinks" [link]="topLink"></ncg-button>
                        </div>
                    </div>
                    <div class="byd-hero-spot__bottom-links" *ngIf="data.bottomLinks">
                        <ncg-button *ngFor="let bottomLink of data.bottomLinks" [link]="bottomLink"></ncg-button>
                    </div>

                    <ng-container
                        *ngIf="
                            data.legal &&
                            (data.legal.energyLabels || data.legal.legalText || data.additionalLegal?.length || data.legal.extraHeroText)
                        "
                    >
                        <div
                            [ngClass]="{
                                'legal-text-moved': data.showLegalTextUnderMobile,
                                'legal-text-moved-desktop': data.showLegalTextUnderDesktop
                            }"
                            class="byd-hero-spot__legal"
                        >
                            <ncg-legal
                                [energyLabels]="data.legal.energyLabels"
                                [legalText]="'<b>' + data.legal.modelName + ':</b> ' + ' ' + data.legal.legalText"
                            ></ncg-legal>
                            <ng-container *ngFor="let item of data.additionalLegal">
                                <ncg-legal
                                    [energyLabels]="item.energyLabels"
                                    [legalText]="'<b>' + item.modelName + ':</b> ' + ' ' + item.legalText"
                                ></ncg-legal>
                            </ng-container>
                            <ncg-legal [legalText]="data.legal.extraHeroText"></ncg-legal>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BydHeroSpotComponent extends SpotBaseDirective implements OnInit {
    static ref = 'bydhero';
    @Input() data: BydHeroSpot;

    level: any = '2';
    headlineClass = 'byd-hero-spot__title';

    constructor(@Inject(SpotsConfig) public readonly spotsConfig: SpotsConfig) {
        super();
    }

    ngOnInit(): void {
        if (this.data.titleLevel) {
            this.level = this.data.titleLevel;
        }

        if (this.data.titleRenderAs) {
            this.headlineClass += ` h${this.data.titleRenderAs}`;
        }
    }
}
